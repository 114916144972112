<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CCard v-if="isResetRequested === false">
          <CCardBody>
            <CAlert :color="alertType" v-if="message"> {{ message }}</CAlert>

            <CRow style="margin-bottom: 1rem">
              <CCol>
                <CButton color="secondary" @click="goBack()" class="btn-lg" style="margin-right: 15px">
                  <CIcon name="cilChevronLeft" />
                  Back to inventories
                </CButton>
              </CCol>
            </CRow>
            <CRow v-if="shouldBeListVisible" style="margin-bottom: 1rem">
              <CCol sm="1">
                <CInputRadio @change="calendarView = false" label="List View" type="radio" name="types"
                  :checked.sync="listView" />
              </CCol>
              <CCol sm="1">
                <CInputRadio @change="handleRadioChange" label="Calendar View" type="radio" name="types"
                  :checked.sync="calendarView" />
              </CCol>
            </CRow>
            <CRow v-if="calendarView">
              <CCol sm="2">
                <CSelect label="Experience" :value.sync="experienceId" :plain="true" :options="experiences"
                  placeholder="Select an experience" @change="filterByInventoryItem()">
                </CSelect>
              </CCol>
              <!-- <CCol sm="2">
                <label>State</label>
                <CMultiSelect
                  :multiple="true"
                  :selection="true"
                  v-model="paymentStatusValues"
                  @update="appendValue($event)"
                  optionsEmptyPlaceholder="No options placeholder"
                  selectionType="text"
                  :options="options"
                  :selected="paymentStatusValues"
                />
              </CCol> -->
              <!-- <CCol sm="2">
                <CSelect
                  label="Inventory"
                  :value.sync="inventoryId"
                  :plain="true"
                  :options="inventories"
                  placeholder="Select an inventory"
                  @change="sortItems()"
                >
                </CSelect>
              </CCol>
              <CCol sm="2">
                <CSelect
                  label="Inventory items"
                  :value.sync="inventoryItemId"
                  :plain="true"
                  :options="inventoryItemsList"
                  placeholder="Select an inventory item"
                  @change="filterByInventoryItem()"
                >
                </CSelect>
              </CCol> -->
              <!-- <CCol sm="0">
                <CButton
                  color="info"
                  @click="getList()"
                  class="btn"
                  style="margin-top: 28px; margin-right: 1vw"
                >
                  <CIcon name="cil-list" />
                  Filter
                </CButton>
              </CCol> -->
              <CCol sm="0">
                <CButton color="info" @click="resetFilters()" class="btn" style="margin-top: 28px">
                  <CIcon name="cilXCircle" />
                  Reset filters
                </CButton>
              </CCol>
            </CRow>
            <CRow v-else>
              <CCol sm="2">
                <CSelect label="Experience" :value.sync="experienceId" :plain="true" :options="experiences"
                  placeholder="Select an experience">
                </CSelect>
              </CCol>
              <CCol sm="2">
                <label>State</label>
                <CMultiSelect :multiple="true" :selection="true" v-model="paymentStatusValues"
                  @update="appendValue($event)" optionsEmptyPlaceholder="No options placeholder" selectionType="text"
                  :options="options" :selected="paymentStatusValues" />
              </CCol>
              <CCol sm="2">
                <CSelect label="Inventory" :value.sync="inventoryId" :plain="true" :options="inventories"
                  placeholder="Select an inventory" @change="sortItems()">
                </CSelect>
              </CCol>
              <CCol sm="2">
                <CSelect label="Inventory items" :value.sync="inventoryItemId" :plain="true" :options="inventoryItemsList"
                  placeholder="Select an inventory item" @change="getAvailabilities()">
                </CSelect>
              </CCol>
              <CCol sm="0">
                <CButton color="info" @click="getList()" class="btn" style="margin-top: 28px; margin-right: 1vw">
                  <CIcon name="cil-list" />
                  Filter
                </CButton>
              </CCol>
              <CCol sm="0">
                <CButton color="info" @click="resetFilters()" class="btn" style="margin-top: 28px">
                  <CIcon name="cilXCircle" />
                  Reset filters
                </CButton>
              </CCol>
            </CRow>
            <CDataTable hover columnFilter sorter sortable :items.sync="internalItems" :fields="fields"
              :items-per-page="50" pagination v-if="shouldBeListVisible && listView">
              <template #bookingNumber="{ item }">
                <td>
                  <strong>{{ item.bookingNumber }}</strong>
                </td>
              </template>
              <template #customer="{ item }">
                <td>
                  <strong>{{ item.fullName }} </strong>
                </td>
              </template>
              <template #bookingDates="{ item }">
                <td>
                  <strong>{{ moment(new Date(item.bookingDates.split(" - ")[0])).format($dateFormat).split(" ")[0] + " - "
                    + moment(new Date(item.bookingDates.split(" - ")[1])).format($dateFormat).split(" ")[0] }} </strong>
                </td>
              </template>
              <template #guestNames="{ item }">
                <td>
                  <strong>{{ item.guestNames }} </strong>
                </td>
              </template>
              <template #createDate="{ item }">
                <td>
                  <strong>{{ moment(new Date(item.createDate)).format($dateFormat) }}</strong>
                </td>
              </template>
              <template #bookingTotal="{ item }">
                <td>
                  <strong>{{ item.bookingTotal }}</strong>
                </td>
              </template>
              <template #state="{ item }">
                <td>
                  <CBadge v-if="item.state < 3" :color="getBadge(item.state)">
                    {{ item.state == 2 ? "Paid" : "Unpaid" }}
                  </CBadge>
                  <CBadge v-if="item.state == 3" color="danger">
                    Cancelled
                  </CBadge>
                  <CBadge v-if="item.state == 4" color="danger">
                    Expired
                  </CBadge>
                  <CBadge v-if="item.state == 5" color="light">
                    Confirmed
                  </CBadge>
                  <CBadge v-if="item.state == 6" color="success">
                    Partial Paid
                  </CBadge>
                </td>
              </template>
              <template #ACTIONS="{ item }">
                <td style="width: 15%">
                  <CButton color="info" @click="getDetails(item.id)">
                    <CIcon name="cil-list" /> Details
                  </CButton>
                  <CButton v-if="user.hasGlobalRights" color="danger" style="margin-left: 10px" @click="
                    warningModal = true;
                  deleteBooking = item.id;
                  ">
                    <CIcon name="cil-trash" /> Delete
                  </CButton>
                </td>
              </template>
            </CDataTable>

            <CModal v-model="deleteBooking" title="Are you sure to delete selected booking ?" color="danger"
              :show.sync="warningModal" @update:show="closeModalDeleteBooking">
              <CRow>
                <CCol col="9">
                  <h7>You are about to delete the selected booking with id
                    {{ deleteBooking }} !!</h7>
                </CCol>
              </CRow>
            </CModal>

            <ejs-schedule v-if="calendarDataLoaded && !listView" id="Schedule" height="550px" width="100%"
              :quickInfoTemplates="quickInfoTemplates" :popupOpen="popupOpen" :allowDragAndDrop='false'
              :selectedDate="selectedDate" :eventRendered="onEventRendered" :eventSettings="eventSettings"
              :quickInfoOnSelectionEnd="true" :group="group" currentView="TimelineMonth">
              <e-resources>
                <e-resource field="ExperienceId" title="Experience" name="Experiences" :dataSource="calendarExperiences"
                  textField="label" idField="value" colorField="color">
                </e-resource>
              </e-resources>


              <template v-slot:headerTemplate="{ data }">
                <div class="quick-info-header">

                </div>
              </template>

              <template v-slot:footerTemplate="{ data }">
                <div class="quick-info-footer">
                  <ejs-button style="color: #e3165b" id="more-details" cssClass="e-flat" content="Open booking"
                    v-on:click.native="moreDetails(data)"></ejs-button>
                </div>
              </template>
              <e-views>
                <e-view option="TimelineDay"></e-view>
                <e-view option="TimelineWeek"></e-view>
                <e-view option="TimelineWorkWeek"></e-view>
                <e-view option="TimelineMonth"></e-view>
                <e-view option="Agenda"></e-view>
              </e-views>
            </ejs-schedule>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>

    <CSpinner style="display: block; position: fixed; top: 50%; left: 50%" v-if="isResetRequested === true"
      color="primary" />
    <BookingModal v-if="showBookingModal" :bookingId.sync="selectedBookingId" :bookingTitle.sync="selectedBookingTitle"
      :showBookingModalProp.sync="showBookingModal"></BookingModal>
  </CRow>
</template>
<script>
import axios from "axios";
import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import "/node_modules/@syncfusion/ej2-base/styles/material.css";
import "/node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "/node_modules/@syncfusion/ej2-calendars/styles/material.css";
import "/node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
import "/node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "/node_modules/@syncfusion/ej2-navigations/styles/material.css";
import "/node_modules/@syncfusion/ej2-popups/styles/material.css";
import "/node_modules/@syncfusion/ej2-vue-schedule/styles/material.css";
import { ButtonComponent } from "@syncfusion/ej2-vue-buttons";
import { extend } from "@syncfusion/ej2-base";
import {
  WorkWeek,
  Month,
  Week,
  ScheduleComponent,
  ViewDirective,
  ViewsDirective,
  ResourceDirective,
  ResourcesDirective,
  Agenda,
  TimelineViews,
  TimelineMonth,
  Resize,
  DragAndDrop,
} from "@syncfusion/ej2-vue-schedule";
import { MultiSelect, DropDownList } from "@syncfusion/ej2-dropdowns";
import { DateTimePicker } from "@syncfusion/ej2-calendars";
import { isNullOrUndefined } from "@syncfusion/ej2-base";
import { AddEvent } from "@/views/calendarview/AddEvent.vue";
import { DateTimePickerComponent } from "@syncfusion/ej2-vue-calendars";
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";
import BookingModal from "@/components/BookingModal.vue"

export default {
  props: {
    items: [],
    shouldBeListVisible: Boolean,
  },
  name: "CalendarView",
  provide: {
    schedule: [
      WorkWeek,
      Month,
      Week,
      Agenda,
      TimelineViews,
      TimelineMonth,
      Resize,
      DragAndDrop,
    ],
  },
  components: {
    FullCalendar,
    BookingModal,
    "ejs-schedule": ScheduleComponent,
    "e-view": ViewDirective,
    "e-views": ViewsDirective,
    "e-resource": ResourceDirective,
    "e-resources": ResourcesDirective,
    "ejs-datetimepicker": DateTimePickerComponent,
    "ejs-dropdownlist": DropDownListComponent,
    'ejs-button': ButtonComponent,
  },
  data: function () {
    return {
      eventSettings: {
        dataSource: [],
      },
      calendarDataLoaded: false,
      calendarInventoryItemsList: [],
      calendarInventories: [],
      calendarExperiences: [],
      group: {
        allowGroupEdit: true,
        enableCompactView: false,
        resources: ["Experiences"],
      },
      quickInfoTemplates: {
        header: "headerTemplate",
        footer: "footerTemplate"
      },
      selectedBookingId: null,
      selectedBookingTitle: null,
      showBookingModal: false,
      selectedDate: new Date(),
      paymentStatusValues: [2, 5, 6],
      calendarItemSubject: null,
      calendarItemNote: null,
      relatedBookings: [],
      isBusy: true,
      calendarItem: {
        inventoryItemId: null,
        startTime: null,
        endTime: null,
        isBlocked: false,
        subject: null,
        customerId: null,
        notes: null,
      },
      relatedBookingId: "00000000-0000-0000-0000-000000000000",
      isAvailable: false,
      showBookingModal: false,
      option: null,
      options: [
        {
          value: 1,
          text: "Unpaid",
        },
        {
          value: 2,
          text: "Paid",
        },
        {
          value: 3,
          text: "Canceled",
        },
        {
          value: 4,
          text: "Expired",
        },
        {
          value: 5,
          text: "Confirmed",
        },
        {
          value: 6,
          text: "PartialPaid",
        },
      ],
      bookings: [],
      listView: true,
      warningModal: false,
      calendarView: false,
      shouldBeBlocked: false,
      deleteBooking: null,
      experienceId: "00000000-0000-0000-0000-000000000000",
      selectedTimeSlotId: "00000000-0000-0000-0000-000000000000",
      paymentStatus: "",
      inventoryId: "00000000-0000-0000-0000-000000000000",
      inventoryItemId: "00000000-0000-0000-0000-000000000000",
      experiences: [],
      paymentStatuses: [],
      internalItems: [],
      inventories: [],
      shouldBeUnblocked: false,
      intermediateAvailabiltyResponse: [],
      isResetRequested: false,
      visiblePopUp: false,
      user: null,

      calendarItemToBeDeleted: "00000000-0000-0000-0000-000000000000",
      popupTitle: "",
      popupContent: "",
      start: null,
      end: null,
      inventoryItemsList: [],
      alertType: "danger",
      message: null,
      blocked: {
        id: "",
        backgroundColor: "",
        guestNames: "",
        isBooking: false,
        isPaid: false,
        start: "",
        title: "Unavailable",
      },
      fields: [
        "bookingNumber",
        "bookingDates",
        "customer",
        "guestNames",
        "createDate",
        "bookingTotal",
        {
          key: "state",
          filter: false,
          sorter: false,
        },
        {
          key: "ACTIONS",
          sorter: false,
          filter: false,
        },
      ],
      //Alert
      alertType: "danger",
      message: null,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        events: [],
        eventDidMount: function (eventInfo) {
          eventInfo.el.onmouseenter = function () {
            eventInfo.el.title = eventInfo.event.extendedProps.fullDescription;
          };
        },
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        initialView: "dayGridMonth",
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        eventClick: this.handleClick,
        select: this.handleDateSelect,
        weekends: true,
        timeZone: "UTC",
        initialDate: new Date(),
      },
    };
  },
  computed: {},
  methods: {
    appendValue(event) {
      this.paymentStatusValues = event;
    },
    getBadge(status) {
      switch (status) {
        case 2:
          return "success";
        case 1:
          return "secondary";
        default:
          return "primary";
      }
    },
    popupOpen(args) {
      let isEmptyCell = args.target &&
        (args.target.classList.contains("e-work-cells") ||
          args.target.classList.contains("e-header-cells")); // checking whether the cell is empty or not
      if (
        (args.type === "QuickInfo" || args.type === "Editor") &&
        isEmptyCell
      ) {
        args.cancel = true;
      }
      if (args.type == "Editor") {
        args.cancel = true;
      }
    },
    onEventRendered: function (args) {
      /*       if(args.data.Availability == 1){
        args.element.style.backgroundColor = '#c13434';
      }
      else if(args.data.Availability == 2){
        args.element.style.backgroundColor = '#58c318';
      } */
    },
    filterByInventoryItem() {
      this.getFilteredDataSource(this.eventSettings.dataSource);
    },
    handleDateSelect(selectInfo) {
      let startDisplay;
      let endDisplay;
      if (this.inventoryItemId != "00000000-0000-0000-0000-000000000000") {
        this.start = new Date();
        this.end = new Date();
        let title = this.inventoryItemsList.find(
          (x) => x.value == this.inventoryItemId
        ).label;
        let intermediateEndDate = new Date(selectInfo.endStr);
        intermediateEndDate.setDate(intermediateEndDate.getDate() - 1);
        intermediateEndDate = intermediateEndDate.toISOString();
        if (
          selectInfo.startStr.includes("T") &&
          selectInfo.endStr.includes("T")
        ) {
          startDisplay = new Date(selectInfo.startStr).toJSON();
          endDisplay = new Date(selectInfo.endStr).toJSON();
        } else {
          startDisplay = selectInfo.startStr;
          endDisplay = selectInfo.endStr;
          var elements = intermediateEndDate.split("T");
          intermediateEndDate = elements.at(0);
          endDisplay = intermediateEndDate;
        }
        this.start = selectInfo.startStr;
        this.end = selectInfo.endStr;
        this.popupContent =
          "Are you sure you want to block availability for " +
          title +
          " between specified interval?";
        this.popupTitle =
          "Calendar item management between " +
          startDisplay +
          " and " +
          endDisplay;
        this.visiblePopUp = true;
      }
    },
    closeModalDeleteBooking(status, evt, accept) {
      let self = this;
      if (accept) {
        self.isResetRequested = true;
        axios
          .delete(`${this.$apiAdress}/v1/Booking/${self.deleteBooking}`)
          .then(function (response) {
            self.deleteBooking = null;
            self.warningModal = false;
            self.getList(); //required for populate calendar events filtered by invetoryid and inventoryitem id taken from route
            this.internalItems = this.items;
            self.isResetRequested = false;
          })
          .catch(function (error) {
            self.deleteBooking = null;
            self.warningModal = false;
            self.isResetRequested = false;
          });
      } else {
        this.deleteBooking = null;
        this.warningModal = false;
        self.isResetRequested = false;
      }
    },
    closeModal(status, evt, accept) {
      let self = this;
      if (accept) {
        let self = this;
        if (self.shouldBeUnblocked === true) {
          axios
            .get(
              `${this.$apiAdress}/v1/Calendar/UnblockAvailability?calendarItemId=${self.calendarItemToBeDeleted}`
            )
            .then(function (response) {
              self.calendarItemToBeDeleted =
                "00000000-0000-0000-0000-000000000000";
              self.getAvailabilities();
            })
            .catch(function (error) {
              self.alertType = "danger";
              setTimeout(function () {
                self.message = null;
              }, 10000);
              self.message = error.response.data.message;
            });
        } else {
          self.calendarItem.inventoryItemId = self.inventoryItemId;
          self.calendarItem.startTime = self.start;
          self.calendarItem.endTime = self.end;
          self.calendarItem.notes = self.calendarItemNote;
          self.calendarItem.subject = self.calendarItemSubject;
          self.calendarItem.isBlocked = self.isBlocked;
          axios
            .post(
              `${this.$apiAdress}/v1/Calendar/BlockAvailability/${self.relatedBookingId}`,
              self.calendarItem
            )
            .then(function (response) {
              self.getAvailabilities();
              self.visiblePopUp = false;
            })
            .catch(function (error) {
              self.alertType = "danger";
              setTimeout(function () {
                self.message = null;
              }, 10000);
              self.message = error.response.data.message;
            });
        }
      } else {
        self.visiblePopUp = false;
      }
      self.shouldBeUnblocked = false;
    },
    handleClick(eventInfo) {
      let self = this;
      const link = `/bookings/details/${eventInfo.event.id.toString()}`;
      this.$router.push({ path: link });
    },
    getDetails(id) {
      //visiblePopUp
      if (id != "00000000-0000-0000-0000-000000000000" && id != null) {
        const link = `/bookings/details/${id.toString()}`;
        this.$router.push({ path: link });
      } else {
        this.visiblePopUp = true;
      }
    },
    moreDetails(data) {
      this.selectedBookingId = data.Id;
      this.selectedBookingTitle = data.Subject;
      this.showBookingModal = true;
    },
    goBack() {
      this.$router.go(-1);
    },
    getList() {
      this.isResetRequested = true;
      this.calendarViewEvents = [];
      this.calendarOptions.events = [];
      this.getTableList();
      this.isResetRequested = false;
      //  this.populateCalendarEvents();
    },
    getTableList() {
      let self = this;
      axios
        .get(
          this.$apiAdress +
          "/v1/Booking?experienceId=" +
          self.experienceId +
          "&paymentStatusValues=" +
          JSON.stringify(self.paymentStatusValues) +
          "&inventoryId=" +
          self.inventoryId +
          "&inventoryItemId=" +
          self.inventoryItemId
        )
        .then(function (response) {
          self.internalItems = [];
          self.bookings = response.data;
          self.bookings.map(function (value, key) {
            value.bookingNumber = value.bookingNumber;
            value.bookingTotal = value.bookingTotal;
            value.fullName =
              value.customer.firstName + " " + value.customer.lastName;
            /*             value.createDate = new Date(value.createDate)
                          .toISOString()
                          .split("T")[0]; */

            value.bookingDates =
              new Date(value.startDate).toISOString().split("T")[0] +
              " - " +
              new Date(value.endDate).toISOString().split("T")[0];

            /*             value.createDate = new Date(value.createDate)
                          .toISOString()
                          .split("T")[0]; */

            self.internalItems.push(value);
          });
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    resetFilters() {
      let self = this;
      self.experienceId = "00000000-0000-0000-0000-000000000000";
      if (!!this.$route.query.inventoryItemId) {
        self.inventoryItemId = this.$route.query.inventoryItemId;
      } else {
        self.inventoryItemId = "00000000-0000-0000-0000-000000000000";
      }
      if (!!this.$route.query.inventoryId) {
        self.inventoryId = this.$route.query.inventoryId;
      } else {
        self.inventoryId = "00000000-0000-0000-0000-000000000000";
      }
      self.paymentStatusValues = [2, 5, 6];
      if (self.calendarView) {
        self.getFilteredDataSource(self.eventSettings.dataSource);
      }
      else {
        this.getList();
      }

      this.populateFiltersDataSource("GetAllInventoryItemsWithParent");
    },
    populateCalendarEvents() {
      let self = this;
      self.isResetRequested = true;
      axios
        .get(
          this.$apiAdress +
          "/v1/Calendar/" +
          "GetCalendarEventsBookings?experienceId=" +
          self.experienceId +
          "&paymentStatus=" +
          self.paymentStatuses +
          "&inventoryId=" +
          self.inventoryId +
          "&inventoryItemId=" +
          self.inventoryItemId
        )
        .then(function (response) {
          self.calendarViewEvents = response.data;
          let dataSource = [];
          self.calendarViewEvents.map(function (value, key) {
            value.id = value.id;
            value.title = value.subject == null ? "Unavailable" : value.subject;
            value.startTime = value.startTime;
            value.endTime = value.endTime;
            value.guestNames = value.guestNames;
            value.bookingNo = value.bookingNo;
            value.notes = value.notes;
            if (value.isPaid === true) {
              value.backgroundColor = "#00FF00";
            } else {
              value.backgroundColor = "#808080";
            }

            self.calendarOptions.events.push(value);

            dataSource.push({
              Id: value.id,
              Subject: value.subject == null ? "Unavailable" : value.subject,
              StartTime: new Date(value.startTime),
              EndTime: new Date(value.endTime),
              RelatedBookingId: value.bookingNo,
              Availability: value.isBlocked ? 1 : 2,
              InventoryId: value.inventoryId,
              InventoryItemId: value.inventoryItemId,
              ExperienceId: value.experienceId,
              Description: value.notes,
              IsPaid: value.isPaid,
              IsAllDay: self.isAllDay(value.startTime, value.endTime)
            });
          });
          dataSource = self.getFilteredDataSource(dataSource);
          self.eventSettings.dataSource = extend([], dataSource, null, true);
          self.isResetRequested = false;
          self.calendarDataLoaded = true;
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
      axios
        .get(this.$apiAdress + "/v1/Partner/gettimezoneofpartner")
        .then(function (response) {
          self.calendarOptions.timeZone = response.data;
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    isAllDay(startTime, endTime) {
      var start = new Date(startTime);
      var end = new Date(endTime);
      if (start.getHours() == 0 && start.getMinutes() == 0 && end.getHours() == 0 && end.getMinutes() == 0)
        return true;
      else
        return false;
    },
    populateFiltersDataSource(controllerName) {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/ListItem/" + controllerName)
        .then(function (response) {
          switch (controllerName) {
            case "InventoryList":
              self.inventories = response.data;
              self.calendarInventories = response.data;
              break;
            case "ExperienceList":
              self.experiences = response.data;
              self.calendarExperiences = response.data;
              break;
            case "GetAllInventoryItemsWithParent":
              self.inventoryItemsList = response.data;
              self.calendarInventoryItemsList = response.data.filter(
                (inventoryItem) =>
                  inventoryItem.value != "00000000-0000-0000-0000-000000000000"
              );
              break;
          }
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    sortItems(event) {
      let self = this;
      axios
        .get(
          this.$apiAdress +
          "/v1/ListItem/InventoryItemsFiltered/" +
          self.inventoryId
        )
        .then(function (response) {
          self.inventoryItemsList = response.data;

          if (!self.calendarView) {
            self.isResetRequested = true;
            self.getAvailabilities();
          }
          else {
            self.getFilteredDataSource(self.eventSettings.dataSource);
          }
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    getAvailabilities(event) {
      if (this.shouldBeListVisible === false) {
        this.isResetRequested = true;
        let self = this;
        self.intermediateAvailabiltyResponse = [];
        self.calendarOptions.events = [];
        axios
          .get(
            this.$apiAdress +
            "/v1/Calendar/GetAvailabilities?inventoryItemId=" +
            self.inventoryItemId +
            "&inventoryId=" +
            self.inventoryId
          )
          .then(function (response) {
            self.intermediateAvailabiltyResponse = response.data;
            let dataSource = [];
            self.intermediateAvailabiltyResponse.map(function (value, key) {
              value.id = value.id;
              value.title = value.title;
              value.start = value.start;
              value.fullDescription = value.fullDescription;
              value.guestNames = value.guestNames;
              value.bookingNo = value.bookingNo;
              if (value.isBooking === true) {
                if (value.isPaid === true) {
                  value.backgroundColor = "#00FF00";
                } else {
                  value.backgroundColor = "#808080";
                }
              } else {
                value.backgroundColor = "#ff4d4d";
              }
              self.calendarOptions.events.push(value);


              dataSource.push({
                Id: value.id,
                Subject: value.subject == null ? "Unavailable" : value.subject,
                StartTime: new Date(value.startTime),
                EndTime: new Date(value.endTime),
                RelatedBookingId: value.bookingNo,
                Availability: value.isBlocked ? 1 : 2,
                InventoryId: value.inventoryId,
                InventoryItemId: value.inventoryItemId,
                ExperienceId: value.experienceId,
                Description: value.notes,
                IsPaid: value.isPaid,
                IsAllDay: self.isAllDay(value.startTime, value.endTime)
              });
            });
            dataSource = self.getFilteredDataSource(dataSource);
            self.eventSettings.dataSource = extend([], dataSource, null, true);
            if (self.start != null && self.start != undefined) {
              self.calendarOptions.initialDate = new Date(self.start);
            }
            self.isResetRequested = false;
            self.visiblePopUp = false;
          })
          .catch(function (error) {
            self.alertType = "danger";
            setTimeout(function () {
              self.message = null;
            }, 10000);
            self.message = error;
            self.isResetRequested = false;
            self.visiblePopUp = false;
          });
      }
    },
    handleRadioChange() {
      // Handle the change event
      console.log('Radio selection changed');
      this.listView = false; // or any other logic you need to perform on change

      if (this.calendarViewEvents) {
        if (this.calendarViewEvents.length == 0) {
          this.populateCalendarEvents();
        }
      } else {
        this.populateCalendarEvents();

      }
    },
    getFilteredDataSource(dataSource) {
      if (this.experienceId && this.experienceId != "00000000-0000-0000-0000-000000000000") {
        dataSource = dataSource.filter(data => data.ExperienceId == this.experienceId);
        this.calendarExperiences = this.experiences.filter(experience => experience.value == this.experienceId);
      }
      else {
        this.calendarExperiences = this.experiences;
      }
      if (this.inventoryId && this.inventoryId != "00000000-0000-0000-0000-000000000000") {
        dataSource = dataSource.filter(data => data.InventoryId == this.inventoryId);
        this.calendarInventories = this.inventories.filter(inventory => inventory.value == this.inventoryId);
      }
      else {
        this.calendarInventories = this.inventories;
      }
      if (this.inventoryItemId && this.inventoryItemId != "00000000-0000-0000-0000-000000000000") {
        dataSource = dataSource.filter(data => data.InventoryItemId == this.inventoryItemId);
        this.calendarInventoryItemsList = this.inventoryItemsList.filter(inventoryItem => inventoryItem.value == this.inventoryItemId);
      }
      else {
        this.calendarInventoryItemsList = this.inventoryItemsList;
      }
      return dataSource;
    },
  },
  mounted: function () {
    if (!!this.$route.query.inventoryItemId) {
      this.inventoryItemId = this.$route.query.inventoryItemId;
    }
    if (!!this.$route.query.inventoryId) {
      this.inventoryId = this.$route.query.inventoryId;
    }
    // this.populateCalendarEvents(); //required for populate calendar events filtered by invetoryid and inventoryitem id taken from route
    this.populateFiltersDataSource("ExperienceList");
    this.populateFiltersDataSource("PaymentStatusList");
    this.populateFiltersDataSource("InventoryList");
    this.populateFiltersDataSource("GetAllInventoryItemsWithParent");
    this.internalItems = this.items;
    var user = localStorage.getItem("user");
    if (user != null) {
      this.user = JSON.parse(localStorage.getItem("user"));
    }
  },
};
</script>
<style>
div.fc-event-time {
  color: black !important;
}

div.fc-event-title.fc-sticky {
  color: black !important;
}

.e-popup-footer {
  display: block !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  -webkit-border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(100, 100, 100, 0.8);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.e-content-wrap::-webkit-scrollbar {
  display: inherit;
}

.e-content-wrap:hover::-webkit-scrollbar {
  width: 10px;
}

.e-content-wrap:hover::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  -webkit-border-radius: 8px;
}

.e-content-wrap:hover::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(100, 100, 100, 0.8);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.e-subject.e-text-ellipsis {
  background-color: transparent !important;
}
</style>
  