<template>
  <CRow>
    <CCol col="12" xl="12">

      <transition name="slide">
        <CalendarViewBookings v-if="triedLoad === true" :shouldBeListVisible="true" :items="items"/>

      </transition>
    </CCol>
    <CSpinner style="display:block;position:fixed;top:50%;left:50%" v-if="items.length == 0 && triedLoad === false" color="primary"/>

  </CRow>
</template>

<script>
import axios from "axios";
import '@fullcalendar/core/vdom';
import CalendarViewBookings from "../calendarviewbookings/CalendarViewBookings.vue";

export default {
  name: "Bookings",
  components: {
    CalendarViewBookings
},
  data: () => {
   var self = this;
    return {
      items: [],
      bookingData: [],
      bookings: [],
      fields: [
        "bookingNumber",
        "bookingDates",
        "customer",
        "guestNames",
        "createDate",
        "state",
        "DETAILS",
      ],
      experienceId: "00000000-0000-0000-0000-000000000000",
      paymentStatus:  [2,5,6],
      inventoryId: "00000000-0000-0000-0000-000000000000",
      inventoryItemId: "00000000-0000-0000-0000-000000000000",
      triedLoad : false,
      //Alert
      alertType: "danger",
      message: null,
    };
  },
  computed: {},
  methods: {
    getList() {
      let self = this;
      axios
        .get(
          this.$apiAdress +
            "/v1/Booking?experienceId=" +
            self.experienceId +
            "&paymentStatusValues=" +
            JSON.stringify(self.paymentStatus)+
            "&inventoryId=" +
            self.inventoryId+
            "&inventoryItemId=" +
            self.inventoryItemId
        )
        .then(function (response) {
          self.items = [];
          self.bookings = response.data;
          self.bookings.map(function (value, key) {
            value.bookingNumber = value.bookingNumber;
            value.fullName =
              value.customer.firstName + " " + value.customer.lastName;
            value.bookingDates =
              new Date(value.startDate).toISOString().split("T")[0] +
              " - " +
              new Date(value.endDate).toISOString().split("T")[0];
            value.createDate =new Date(value.createDate).toISOString().split("T")[0] + " " + new Date(value.createDate).toLocaleTimeString();
            value.state = value.state;
            self.items.push(value);
          });
          self.triedLoad=true;

        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },

  },
  mounted: function () {
    this.getList();

  },
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
</style>
